import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Breakpoint } from 'react-socks'

import {
  Container
} from 'components/Grid'

import QuantitySelect from 'components/QuantitySelect'

import {
  getUrl,
  getPrice
} from 'utils/product'

import StoreContext from 'context/Store'

const LineItemEl = styled.div`  
  padding: 20px 0;
  border-top: solid 1px ${p => p.theme.colors.ltGreen};
  text-align: left;  
  user-select: none;

  & > * {
    opacity: ${p => p.disabled ? .5 : 1};
    pointer-events: ${p => p.disabled ? 'none' : 'all'};
    transition: opacity 200ms ease-out;
  }

  &:last-child {
    border-bottom: solid 1px ${p => p.theme.colors.ltGreen};
  }
`

const LineItemContainer = styled(Container)`
  display: flex;  

  ${p => p.theme.media.minWidth('tablet')} {
    align-items: center;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    padding-right: 0;
  }
`

const Image = styled.div`
  position: relative;
  flex: 0 0 75px;
  margin: 0 10px 0 0;
  background-color: ${p => p.theme.colors.ltGreen}; 

  ${p => p.theme.media.minWidth('tablet')} {
    flex: 0 0 120px;
    margin: 0 20px 0 0;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    flex: 0 0 150px;
    margin: 0 20px 0 0;
  }

  ${p => p.theme.media.minWidth('large')} {
    margin: 0 25px 0 0;
  }

  img {
    max-width: 100%;
  }
`

const ImageBox = styled.div`
  position: relative;
  padding-top: ${(906/720) * 100}%;

  > img {
    ${p => p.theme.mixins.fill('absolute')};
    object-fit: cover;
  } 
`

const Details = styled.div`
  position: relative;
  flex: 1;

  ${p => p.theme.media.minWidth('desktop')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Meta = styled.div`
  flex: 1;
`

const VendorTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const Vendor = styled.div`
  ${p => p.theme.typography.tNormal};
  text-transform: uppercase;
`

const Title = styled.div`
  ${p => p.theme.typography.tNormal};
`

const Options = styled.ul`
  ${p => p.theme.typography.tNormal};
  margin: 0;
  padding: 0;
  list-style: none;
  
  li {
    margin: 4px 0;
  }
`

const PriceWrapper = styled.div`
  ${p => p.theme.typography.tNormal};

  ${p => p.theme.media.minWidth('desktop')} {
    margin: 0px 0 0 0;
    flex: 1;

  }  
`

const QuantitySelectWrapper = styled.div`
  flex: 1;
  position: absolute;
  right: 0;
  bottom: 10px;

  & + ${PriceWrapper} {
    ${p => p.theme.media.maxWidth('desktop')} {
      display: none;
    }
  }


  ${p => p.theme.media.minWidth('desktop')} { 
    position: relative;
    right: auto;
    bottom: auto;
  }  
`

const Price = styled.span`
  display: inline-block;
`

const CompareAtPrice = styled.span`
  display: inline-block;
  margin: 0 10px 0 0;
  text-decoration: line-through;
`

const RemoveWrapper = styled.div`
  margin: 12px 0 0 0;

  ${p => p.theme.media.minWidth('desktop')} {
    margin: 0;
  }
`

const Remove = styled.button`
  ${p => p.theme.typography.tNormal};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const LineItem = ({
  id,
  title,
  variant,
  quantity,
  customAttributes,
  ...props
}) => {
  const {
    removeLineItem,
    updateLineItem
  } = useContext(StoreContext)

  const [disabled, setDisabled] = useState(false)
    
  const vendorAttr = customAttributes
    .find(attr => attr.key === 'vendor')
  const vendor = vendorAttr && vendorAttr.value

  const onQuantityAdd = () => {
    setDisabled(true)
    updateLineItem(id, quantity + 1).then(() => {      
      setDisabled(false)
    })    
  }

  const onQuantitySubtract = () => {
    setDisabled(true)
    updateLineItem(id, quantity - 1).then(() => {      
      setDisabled(false)
    })    
  }
  
  const onRemoveClick = () => {
    setDisabled(true)
    removeLineItem(id).then(() => {      
      setDisabled(false)
    })
  }  

  const renderCompareAtPrice = () => {
    if ( !variant.compareAtPrice ) return

    const pcompareAtPrice = parseFloat(variant.compareAtPrice.attrs.amount.value)
    const pprice = parseFloat(variant.price.attrs.amount.value)

    if ( pprice >= pcompareAtPrice ) return

    return (
      <CompareAtPrice>{getPrice(variant.compareAtPrice.attrs.amount.value)}</CompareAtPrice>
    )
  }

  const renderPrice = () => (
    <PriceWrapper>
      {renderCompareAtPrice()}
      <Price>{getPrice(variant.price.attrs.amount.value)}</Price>
    </PriceWrapper>
  )
  
  return (
    <LineItemEl disabled={disabled}>
      <LineItemContainer>
        <Image>
          <Link to={getUrl(variant.product.handle)}>
            <ImageBox>
              <img src={variant.image.src} alt={variant.product.title} />
            </ImageBox>
          </Link>
        </Image>
        <Details>
          <Meta>
            <VendorTitle>
              {vendor && <Vendor>{vendor}</Vendor>}

              <Breakpoint tablet-large down>
                {renderPrice()}
              </Breakpoint>
            </VendorTitle>
            
            <Title>{title}</Title>
            
            <Options>
              {variant.selectedOptions.map(({ name, value }, i) => (
                 <li key={i}>{name}: {value}</li>
               ))}
            </Options>
          </Meta>

          <QuantitySelectWrapper>
            <QuantitySelect
                label='Quantity:'
                quantity={quantity}
                onAdd={onQuantityAdd}
                onSubtract={onQuantitySubtract} />
          </QuantitySelectWrapper>


          {renderPrice()}

          
          <RemoveWrapper>
            <Remove onClick={onRemoveClick}>Remove</Remove>
          </RemoveWrapper>
        </Details>
      </LineItemContainer>
    </LineItemEl>
  )
}

export default LineItem
