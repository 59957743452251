import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  user-select: none;
`

const Button = styled.button`
  padding: 9px;

  i {
    font-size: 8px;
  }
`

const Count = styled.span`
  display: block;
  margin: 0 8px;
  ${p => p.theme.typography.tNormal}
`

const Label = styled.div`
  display: none;
  margin: 0 5px 0 0;
  ${p => p.theme.typography.tNormal};

  ${p => p.theme.media.minWidth('large')} {
    display: block;
  } 
`

const QuantitySelect = ({
  label,
  quantity,
  onAdd,
  onSubtract
}) => {
  return (
    <Wrapper>
      {label && (
         <Label>{label}</Label>
       )}
      <Button onClick={onSubtract}>
        <i className='icon-minus'></i>
      </Button>
      <Count>{quantity}</Count>
      <Button onClick={onAdd}>
        <i className='icon-plus'></i>
      </Button>
    </Wrapper>
  )
}

export default QuantitySelect
