import React, { useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Breakpoint } from 'react-socks'

import Head from 'components/Head'
import StoreContext from 'context/Store'
import LineItem from 'components/LineItem'
import Fade from 'transitions/Fade'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

import {
  H1,
  Paragraph
} from 'components/Typography'

import {
  ButtonPrimary
} from 'components/Button'

import {
  getPrice
} from 'utils/product'

const BagContainer = styled.div`
  margin: 20px 0 0 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 40px 0 0 0;
  }
`

const LineItemContainer = styled(Container)`
  ${p => p.theme.media.minWidth('tablet')} {
    padding-left: 0;
    padding-right: 0;
  }
`

const CheckoutContainer = styled(Container)`
  margin: 20px 0 40px 0;
  text-align: center;

  p {
    ${p => p.theme.media.minWidth('tablet')} {
      margin-top: 30px;
    }
  }
`

const Checkout = styled(ButtonPrimary)`
  width: 100%;
`

const Title = styled(H1)`
  margin: 0 0 20px 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 40px 0 40px 0;
    font-size: 16px;
    text-align: left;
  }  
`

const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
`

const SubtotalTitle = styled.div`
  ${p => p.theme.typography.tMedium};
`

const SubtotalPrice = styled.div`
  ${p => p.theme.typography.tMedium};
`

const Bag = () => {
  const {
    checkout
  } = useContext(StoreContext)

  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.global.setHeaderPadding(true)
  }, [theme.global])  

  const onCheckoutClick = () => {
    window.open(checkout.webUrl)
  }

  const renderCheckout = () => {
    return (
      <CheckoutContainer>
        <Title>Shopping Bag ({checkout.lineItems.length})</Title>
        <Subtotal>
          <SubtotalTitle>Subtotal</SubtotalTitle>
          <SubtotalPrice>
            {getPrice(checkout.subtotalPrice.attrs.amount.value, {
               minimumFractionDigits: 2
             })}
          </SubtotalPrice>
        </Subtotal>
        <Checkout onClick={onCheckoutClick}>Checkout</Checkout>
        <Paragraph>Tax and shipping calculated at checkout</Paragraph>
      </CheckoutContainer>
    )
  }
  
  return (
    <Fade>
    <Head title={'Bag'} />
    <BagContainer>
      <Breakpoint mobile-large down>
        {checkout.lineItems.length > 0 && renderCheckout()}
      </Breakpoint>
      <Row>
        <Col tb={14} dk={15}>
          <LineItemContainer fullBleedMobile={true}>
            {checkout.lineItems.map((lineItem, i) => (
               <LineItem {...lineItem} key={i} />
             ))}

          {checkout.lineItems.length === 0 && (
             <Container>
               <Title>Shopping Bag</Title>
               <Paragraph>You bag is currently empty.</Paragraph>
             </Container>
           )}
          </LineItemContainer>
        </Col>
        <Col tb={10} dk={9}>
          <Breakpoint tablet up>
            {checkout.lineItems.length > 0 && renderCheckout()}
          </Breakpoint>

          {checkout.lineItems.length > 0 && (
             <Breakpoint mobile-large down>
               <CheckoutContainer>
                 <Checkout onClick={onCheckoutClick}>Checkout</Checkout>
               </CheckoutContainer>
             </Breakpoint>
           )}
        </Col>
      </Row>
    </BagContainer>
    </Fade>
  )
}

export default Bag
